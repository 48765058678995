<script setup lang="ts">
import { mdiArrowUp } from "@mdi/js";

const { t } = useI18n();

defineProps<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>();

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>

<template>
  <hw-button
    @click="scrollToTop"
    variant="text"
    size="large"
    class="back-to-top-btn position-absolute text-uppercase w-100"
    :aria-label="t('button.backToTop.ariaLabel')"
    :style="{
      top: top,
      right: right,
      bottom: bottom,
      left: left,
    }"
  >
    <v-icon :icon="mdiArrowUp" size="large" class="me-8" />
    <span class="text-no-wrap">{{ t("button.backToTop.label") }}</span>
  </hw-button>
</template>
